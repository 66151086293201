<template>
    <documentForm :is-new="false" />
  </template>
  
  <script>
  import documentForm from "./documentForm.vue";
  
  export default {
    components: { documentForm },
  };
  </script>
  
  <style></style>
  